import React from 'react'
import Breadcrumbs from '../components/breadcrumbs'
import Helmet from 'react-helmet'
import Layout from "../components/layout"
import DocumentiUtili from "../components/documenti-utili"
import { graphql } from "gatsby"
import DoveSiamo from '../components/dove-siamo'

class Ospedale extends React.Component {
  
  render() { 
    const breadcrumb = [{"title":"Chi siamo"}];
    const data = this.props.data.contentfulContenutoGenerico;
    return (
      <Layout location={this.props.location}>
        <div className="container">
          <Helmet title="Chi siamo"/>
          <Breadcrumbs breadcrumb={breadcrumb}/>
          
          <div className="row">
            <div className="col-md-8">
              <h1>Chi siamo</h1>
              {!!data && 
                <div className="bodytext" dangerouslySetInnerHTML={{ __html: data.testo.childMarkdownRemark.html }} >
                
                </div>
              }
            </div>
            <div className="col-md-4">
              {!!data.titoloSpallaDestra &&
                <div className="webcontent">
                  <h2 className="webcontent__title">{data.titoloSpallaDestra}</h2>
                    <div className="webcontent__abstract" dangerouslySetInnerHTML={{ __html: data.testoSpallaDestra.childMarkdownRemark.html }} >
                    
                    </div>
                </div>
              }
               <DoveSiamo/>
              {!!data.attachment &&
                <DocumentiUtili section="Documenti Utili" data={data.attachment}/>
              }
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}

export default Ospedale;
export const query = graphql`
query ChiSiamoQuery{
  contentfulContenutoGenerico (contentful_id: {eq: "1BUD71xdh7xrxZtJ1WpZEy"}) {
  	titolo
    titoloSpallaDestra
    testoSpallaDestra {
      childMarkdownRemark {
        html
      }
    }
    testo {
      childMarkdownRemark {
        html
      }
    }
  	 attachment {
      title
      file {
        url
      }
    }
	}
}
`
